import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import s from './SearchExample.css';
import Tooltip from '../../Tooltip/Tooltip';
import ConfirmClear from '../ConfirmClear/ConfirmClear';

// Search examples that when clicked, add the example text to the search box
class SearchExample extends Component {
  static propTypes = {
    example: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isSomeText: PropTypes.bool.isRequired,
    clickFn: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      event: null,
      showModal: false,
    };
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleContinue = () => {
    const { example, clickFn } = this.props;
    const { event } = this.state;
    this.setState({ showModal: false });
    clickFn(event, example.pasteText);
  };

  render() {
    const { example, isSomeText, clickFn } = this.props;
    const { buttonText, description, pasteText } = example;
    const { showModal } = this.state;
    return (
      <span key={buttonText} className={s.buttonDiv}>
        <Tooltip text={description} id={buttonText}>
          <Button
            onClick={e => {
              e.persist();
              if (!isSomeText) {
                clickFn(e, pasteText);
              } else {
                this.setState({ event: e, showModal: true });
              }
            }}
            bsSize="xsmall"
            bsStyle="link"
            style={{ padding: '0 2px 0px', margin: 0, borderBottomWidth: 0 }}
          >
            {buttonText}
          </Button>
        </Tooltip>
        <ConfirmClear
          showModal={showModal}
          onCancel={this.handleCancel}
          onContinue={this.handleContinue}
        />
      </span>
    );
  }
}

export default connect()(SearchExample);
