/* eslint-disable camelcase */
import React, { useEffect, useState, ReactElement } from 'react';

import { Button } from 'react-bootstrap';
import { useFMD } from './fmdMachine';
import NetworkSelectBox from '../NetworkSelectBox';
import { TissueNetwork } from '../shared/sharedTypes';
import { areEntrezListsEqual } from '../../core/util';

interface FMDQueryComponentProps {
  createSubmitButton: (onSubmit: any) => React.JSX.Element;
  entrezList: string[];
}

const FMDQueryComponent = ({
  createSubmitButton,
  entrezList,
}: FMDQueryComponentProps): ReactElement | null => {
  const [stateFMD, sendFMD] = useFMD();
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    entrezList: entrezListContext,
    integrations,
    relevantNetworks,
    selectedNetwork,
    title,
  } = stateFMD.context;

  useEffect(() => {
    // Entrez are sometimes a string, sometimes a number, this prevents any mixups
    // TODO: refactor dependencies to use TypeScript and remove
    if (!areEntrezListsEqual(entrezList, entrezListContext)) {
      sendFMD({
        type: 'UPDATE_ENTREZ_LIST',
        entrezList: entrezList.sort(),
      });
    }
  }, [entrezList, entrezListContext, sendFMD]);

  const hasNetworks = stateFMD.matches('networksManager.hasNetworks');

  const integrationSlugs = new Set(
    integrations.map((item: { slug: string }) => item.slug),
  );
  const filteredRelevant = relevantNetworks.filter((network: TissueNetwork) =>
    integrationSlugs.has(network.slug),
  );

  const onSubmit = () => {
    sendFMD({ type: 'FETCH_COMMUNITY' });
  };

  const onNetworkSelect = (newNetwork: TissueNetwork) => {
    sendFMD({
      type: 'UPDATE_SELECTED_NETWORK',
      selectedNetwork: newNetwork,
    });
  };

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value;
    sendFMD({
      type: 'UPDATE_TITLE',
      title: newTitle,
    });
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        alignItems: 'center',
        justifyItems: 'right',
      }}
    >
      <div
        style={{
          display: 'inline-flex',
          margin: '0 10px 0 0',
          padding: 0,
          alignItems: 'center',
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label style={{ margin: '0 5px 0 0' }} htmlFor="titleInput">
          Title:
        </label>
        {isEditMode ? (
          <input
            type="text"
            className="form-control"
            id="titleInput"
            value={title}
            onChange={onTitleChange}
            style={{
              padding: '0 0 0 5px',
              verticalAlign: 'middle',
            }}
          />
        ) : (
          <span
            style={{
              marginRight: '10px',
              verticalAlign: 'middle',
              fontSize: '18px',
            }}
          >
            {title}
          </span>
        )}
        {isEditMode ? null : (
          <Button
            id="editTitleButton"
            bsStyle="default"
            onClick={() => setIsEditMode(true)}
          >
            <i className="fa fa-pencil" style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </div>
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <NetworkSelectBox
          isDisabled={!hasNetworks}
          integrationData={integrations}
          filteredRelevant={filteredRelevant}
          onSelect={onNetworkSelect}
          selection={selectedNetwork}
          tissue={selectedNetwork}
        />
      </div>
      <div>{createSubmitButton(onSubmit)}</div>
    </div>
  );
};

export default React.memo(FMDQueryComponent);
