// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:\"Nunito\",sans-serif;--font-size:13px;--font-size-header:28px;--hover-color:#337ab7;--brand-color:#337ab7;--border-color:#ddd;--notification-color:#ff5a5f;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}.O9oW9>nav>div>div>ul>li>a[role=button]{padding-top:10px}a[class=navbar-brand]{padding:8px 0 0 15px}.O9oW9>nav>div>div>ul>li>a[class=dropdown-toggle]{padding-top:15px}.O9oW9>nav{background:#fff;border-color:#fff;border-radius:0;opacity:.9}.O9oW9>nav>div{width:100%;margin:0;padding:0}._39jHd{background-color:#337ab7;background-color:var(--brand-color)}._2AXOj{display:grid;grid-template-columns:auto 10% 10%;-ms-flex-align:center;align-items:center}._2AXOj>div{text-align:right;padding:14px 0;color:#fff}._2OrdP{text-align:left!important;font-size:16px}._2AXOj>div>button{border:none;border-bottom:1px solid;background:none!important;padding:0!important}._2AXOj>div>button:hover{border-bottom:none}", ""]);
// Exports
exports.locals = {
	"root": "O9oW9",
	"bannerNav": "_39jHd",
	"banner": "_2AXOj",
	"bannerMain": "_2OrdP"
};
module.exports = exports;
