import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Col, Grid, Row } from 'react-bootstrap';

import s from './SearchBox.css';
import { looksLikeList } from '../common/common';
import SearchDropdown from '../SearchDropdown';
import ApplicationContext from '../../ApplicationContext';
import { hasReservedUrlChars } from '../../../core/util';

// Truncate long aliases adding ellipsis after a certain length.
function formatAliases(aliases) {
  // If no aliases, return blank.
  if (aliases === '') {
    return '';
  }

  // Otherwise, delete aliases beginning with the first alias that starts
  // after the len'th character. If no such truncation is possible (e.g., if
  // there is only one very long alias), return everything even though the
  // result may exceed len characters.
  const len = 30;
  const idx = aliases.substring(len, aliases.length).indexOf('|');
  if (idx === -1) {
    return `(${aliases.replace(/\|/g, ', ')})`;
  }
  return `(${aliases.substring(0, len + idx).replace(/\|/g, ', ')}, ... )`;
}

const SearchBox = ({
  onListWarning,
  onSelect,
  onTargetSelect,
  setValue,
  url,
  value,
}) => {
  const globalContext = useContext(ApplicationContext);
  const target = useSelector(state => state.currentSearch.searchTarget);
  const [genes, setGenes] = useState([]);
  const selectRef = useRef(null);

  // For delaying search calls by 300 ms (default delay from jquery-
  // tokeninput). For implementation, see
  // https://stackoverflow.com/questions/13480706/how-to-create-delay-before-ajax-call.
  const [myTimeout, setMyTimeout] = useState(null);

  const handleChange = useCallback(
    val => {
      if (hasReservedUrlChars(val)) {
        onListWarning(true, val);
        return;
      }
      if (val.trim() === '') {
        // Clear list warning of parent (if any)
        onListWarning(false);
        setGenes([]);
        return;
      }
      if (looksLikeList(val)) {
        onListWarning(true, val);
      } else {
        onListWarning(false);
        globalContext
          .fetch(url + val)
          .then(response => response.json())
          .then(json => {
            if (Array.isArray(json)) setGenes(json);
            else setGenes([]);
          });
      }
    },
    [onListWarning],
  );

  useEffect(() => {
    // Delay controller
    if (myTimeout) clearTimeout(myTimeout);
    // Delay until user stops typing
    setMyTimeout(
      setTimeout(() => {
        handleChange(value);
      }, 300),
    );
  }, [value]);

  const options = genes.map(gene => ({
    value: `${gene.standard_name}-${gene.entrez}-${gene.aliases}-${
      gene.description
    }-${gene.terms.join('|')}`,
    gene,
    label: (
      <div>
        <div style={{ fontSize: '110%' }}>
          <strong>
            {gene.standard_name} {gene.description}
          </strong>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
            paddingLeft: '20px',
            fontSize: '90%',
            color: '#666',
          }}
        >
          {gene.terms &&
            gene.terms.map((term, i) => (
              <span
                key={i}
                style={{
                  whiteSpace: 'normal',
                  backgroundColor: '#777',
                  color: 'white',
                  fontSize: '10px',
                  padding: '2px 5px',
                  borderRadius: '3px',
                  marginRight: '5px',
                }}
              >
                {term}
              </span>
            ))}
        </div>
        <span className={s.resultSubtitle}>
          Entrez ID: {gene.entrez}
          {gene.aliases.length &&
            `, Aliases: ${formatAliases(gene.aliases)
              .replace('(', '')
              .replace(')', '')}`}
        </span>
      </div>
    ),
  }));

  const handleSelectChange = selectedOption => {
    onSelect(selectedOption.gene);
  };

  const handleInputChange = inputValue => {
    setValue(inputValue);
    return inputValue; // return the inputValue to maintain internal react-select state.
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: '2px 6px',
      textAlign: 'left',
      backgroundColor: state.isFocused ? '#f5f5f5' : 'white',
      color: 'black',
      whiteSpace: 'normal', // Ensure the text wraps
      overflow: 'visible', // Allow the text to overflow and wrap
    }),
    control: (provided, state) => ({
      ...provided,
      // If the control is focused, adjust the borderColor, boxShadow, etc.
      borderColor: state.isFocused ? '#66AFE9' : provided.borderColor,
      outline: 0,
      boxShadow: state.isFocused
        ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6)'
        : null,
    }),
    input: provided => ({
      ...provided,
      fontSize: '90%',
      color: '#666',
    }),
    menu: provided => ({
      ...provided,
    }),
    indicatorsContainer: provided => ({
      ...provided,
      display: 'none',
    }),
  };

  const selectComponent = (
    <Select
      ref={selectRef}
      autoFocus
      options={options}
      value={options.find(opt => opt.value.includes(value))}
      onChange={handleSelectChange}
      onInputChange={handleInputChange}
      menuIsOpen={options.length > 0}
      controlShouldRenderValue={false}
      inputValue={value}
      styles={customStyles}
      placeholder=""
    />
  );

  const withSelect = (
    <Grid className={s.grid}>
      <Row>
        <Col className={s.column} sm={3}>
          <SearchDropdown onTargetSelect={onTargetSelect} target={target} />
        </Col>
        <Col className={s.column} sm={9}>
          {selectComponent}
        </Col>
      </Row>
    </Grid>
  );

  const content = onTargetSelect ? withSelect : selectComponent;
  return <div className={s.search}>{content}</div>;
};

SearchBox.propTypes = {
  onListWarning: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onTargetSelect: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

SearchBox.defaultProps = {
  onTargetSelect: null,
};

export default withStyles(s)(SearchBox);
