import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Button } from 'react-bootstrap';
import history from '../../history';

import GeneSelect from './GeneSelect';
import s from './GeneSelectButton.css';

/*
 *  The presentational component of the gene selection button UI. Clicking on the button
 *  will bring up a modal dialog to add/remove genes from the current selection
 */
const GeneSelectButton = ({
  genes,
  buttonText,
  style,
  bsStyle,
  bsSize,
  disabled,
  handleSubmit,
}) => {
  const [geneSelectIsOpen, setGeneSelectIsOpen] = useState(false);
  if (history?.location?.pathname.includes('seek')) return null;

  let geneString = '';
  // Show button text
  if (typeof buttonText === 'function') geneString = buttonText(genes);
  else if (buttonText) geneString = buttonText;
  else if (genes.length < 5)
    // Show gene symbols
    genes.forEach((gene, idx, array) => {
      geneString += gene.standard_name + (idx < array.length - 1 ? ', ' : '');
    });
  else geneString = `(${genes.length}) genes`;

  return (
    <div className={s.root} style={{ ...style }}>
      <Button
        bsStyle={bsStyle}
        bsSize={bsSize}
        disabled={disabled}
        onClick={() => {
          setGeneSelectIsOpen(true);
        }}
      >
        {geneString}
      </Button>
      <GeneSelect
        genes={genes}
        handleSubmit={handleSubmit}
        onHide={() => setGeneSelectIsOpen(false)}
        show={geneSelectIsOpen}
      />
    </div>
  );
};

GeneSelectButton.propTypes = {
  // Text of the button. If null, the button text will be the gene symbols
  buttonText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element,
  ]),

  // Should the component be disabled
  disabled: PropTypes.bool,

  // Selected genes
  genes: PropTypes.arrayOf(PropTypes.object),

  // Function to override default submit action
  handleSubmit: PropTypes.func,

  // Bootstrap button style
  bsStyle: PropTypes.string,

  // Bootstrap button size
  bsSize: PropTypes.string,

  style: PropTypes.shape({}),
};

GeneSelectButton.defaultProps = {
  disabled: false,
  genes: [],
  buttonText: '',
  bsStyle: 'default',
  bsSize: null,
  style: {},
  handleSubmit: null,
};

/*
 *  GeneSelectButton injected with current state
 */
export const GeneSelection = props => {
  const genes = useSelector(state => state.geneSelection);
  return <GeneSelectButton {...props} genes={genes} />;
};

export default withStyles(s)(GeneSelectButton);
