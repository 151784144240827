import React from 'react';
import history from '../../history';
import activityOptions, { ActivityOptions } from '../shared/activities';
import { getUrlFromActivity } from '../../core/util';

interface RouteMapping {
  [key: string]: string;
}

const generateRouteMapping = (
  // eslint-disable-next-line @typescript-eslint/no-shadow
  activityOptions: ActivityOptions,
  prefix = '',
): RouteMapping => {
  let mapping: RouteMapping = {};

  Object.keys(activityOptions).forEach((key: string) => {
    const option = activityOptions[key];
    const prefixKey = `${prefix}${key}`;
    mapping[prefixKey] = `${option.text}`;

    if (option.subPaths) {
      const subPathsMapping = generateRouteMapping(
        option.subPaths,
        `${prefixKey}__`,
      );
      mapping = { ...mapping, ...subPathsMapping };
    }

    if (option.subSearch) {
      const subSearchMapping = generateRouteMapping(
        option.subSearch,
        `${prefixKey}__`,
      );
      mapping = { ...mapping, ...subSearchMapping };
    }
  });

  return mapping;
};

const routeMapping: RouteMapping = {
  '': 'Home',
  ...generateRouteMapping(activityOptions),
};

const Breadcrumb: React.FC = () => {
  if (!history) return null;

  const pathName = history?.location?.pathname.substring(1);
  const search = history?.location?.search;

  // Don't render if on home page
  if (pathName === '') return null;

  const pathList = pathName?.split('/').filter((path: string) => path !== '');
  const lastPathIndex = pathList.length - 1;

  const breadcrumbItems = pathList
    .map((path: string, index: number) => {
      const routeMappedPath = `${pathList.slice(0, index + 1).join('__')}`;
      if (!routeMapping[routeMappedPath]) return null;
      const routeTo = `/${pathList.slice(0, index + 1).join('/')}`;
      let text = routeMapping[routeMappedPath] || path;
      const activity = activityOptions[path];

      // If the activity has a customUrl, set the text to the activity's text
      if (activity?.customUrl) {
        const { text: activityText } = activity;
        text = activityText;
      }

      // If the activity has subSearch and the search query matches one of them, set the text to the subSearch's text
      if (activity?.subSearch) {
        // TODO - the following needs to be more generic, right now specific to DeepSEA
        // TODO - make nest `subSearch` possible to appear at any level of the breadcrumb
        const searchKey = new URLSearchParams(search).get('analysis');
        if (searchKey && activity.subSearch[searchKey]) {
          const { text: subSearchText } = activity.subSearch[searchKey];
          text = subSearchText;
        }
      }

      // If the path is static but not the last path, make it plain text
      // TODO - the following needs to be more generic for routes with dynamic endings,
      //  right now it is specific to Expecto & Networks with /gene or /jobs as the last static path
      if ((path === 'gene' || path === 'jobs') && index !== lastPathIndex) {
        return <span key={routeTo}>{text}</span>;
      }

      return (
        <span key={routeTo}>
          {index !== lastPathIndex ? (
            <a href={getUrlFromActivity(activity)}>{text}</a>
          ) : (
            text
          )}
          {index < lastPathIndex ? ' / ' : ''}
        </span>
      );
    })
    .filter(Boolean); // Remove null elements

  return (
    <div
      style={{
        position: 'absolute',
        top: '50px',
        left: 0,
        padding: '5px 0 0 15px',
        width: '300px',
        fontSize: '15px',
        fontWeight: 600,
        backgroundColor: 'white',
      }}
    >
      <span style={{ opacity: 0.8 }}>
        <a href="/">Home</a> {breadcrumbItems.length > 0 && ' / '}
        {breadcrumbItems}
      </span>
    </div>
  );
};

export default Breadcrumb;
