// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:\"Nunito\",sans-serif;--font-size:13px;--font-size-header:28px;--hover-color:#337ab7;--brand-color:#337ab7;--border-color:#ddd;--notification-color:#ff5a5f;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}._1UUMy{text-align:center}", ""]);
// Exports
exports.locals = {
	"root": "_1UUMy"
};
module.exports = exports;
