import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Alert } from 'react-bootstrap';
import s from './ReviewMsg.css';

class ReviewMsg extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    let genes = this.props.data.map(e => e.query);
    genes = genes.join(', ');
    if (genes) {
      return (
        <div className={s.reviewmsg}>
          <Alert className={s.alert} bsStyle="warning">
            <p>
              <i className="fa fa-exclamation-circle" /> The following search
              term(s) matched multiple genes:
            </p>
            <p>
              <strong>{genes}</strong>
            </p>
            <p>Please confirm or revise mappings in the search table below.</p>
          </Alert>
        </div>
      );
    }
    return null;
  }
}

export default withStyles(s)(ReviewMsg);
