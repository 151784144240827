// Refactored by j-funk into functional component 2023/09
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import SearchableSelect from '../SearchableSelect';

function getRelevance(integ) {
  if (!integ || !integ.context.term) return 0;
  let score = 0.0;
  if (integ.score) {
    // eslint-disable-next-line prefer-destructuring
    score = integ.score;
  }
  const bins = Math.round((score - 0.5) / 0.1);
  return Math.max(bins, 0);
}

const getIntegrationGroupData = (
  theProps,
  setCustomStyles,
  setNetworkOptions,
) => {
  const { filteredRelevant, integrationData } = theProps;

  const tmpIntegrationData = integrationData.map((obj, idx) => ({
    ...obj,
    selected: false,
    label: obj.context.title,
    value: idx,
  }));

  if (tmpIntegrationData.length) {
    const idxLookup = {};
    const titleScores = {};
    filteredRelevant.forEach(relevantInteg => {
      titleScores[relevantInteg.context.title] = relevantInteg.score;
    });
    tmpIntegrationData.forEach(integ => {
      if (integ.label in titleScores) {
        integ.score = titleScores[integ.label]; // eslint-disable-line
      }
    });

    Object.keys(idxLookup).forEach(key => {
      if (tmpIntegrationData[key]) {
        tmpIntegrationData[key].score = idxLookup[key];
      }
    });

    const oldSelectIdx = tmpIntegrationData.findIndex(integ => integ.selected);
    if (oldSelectIdx !== -1 && tmpIntegrationData[oldSelectIdx]) {
      tmpIntegrationData[oldSelectIdx].selected = false;
    }

    const suggestedGroupData = tmpIntegrationData
      .filter(integ => getRelevance(integ) > 2)
      .sort((a, b) => b.score - a.score)
      .map(e => ({ ...e }));

    const theGlobalIdx = tmpIntegrationData.findIndex(
      integ => integ.label === 'global',
    );
    const theGlobal = tmpIntegrationData.splice(theGlobalIdx, 1)[0];
    suggestedGroupData.splice(0, 0, theGlobal);

    const integrationGroupData = [
      {
        label: `Suggested Integrations`,
        options: suggestedGroupData,
      },
      {
        label: 'All Integrations',
        options: tmpIntegrationData,
      },
    ];

    const customStyles = {
      option: (base, { data }) => {
        const integ = integrationData[data.value];
        const ofFive = getRelevance(integ);
        const theContent = {
          0: '""',
          1: '""',
          2: '""',
          3: `'\\f005\\f005\\f005\\f006\\f006'`,
          4: `'\\f005\\f005\\f005\\f005\\f006'`,
          5: `'\\f005\\f005\\f005\\f005\\f005'`,
        };

        return {
          ...base,
          textAlign: 'left',
          whiteSpace: 'nowrap',
          marginRight: '70px',
          '&::after': {
            fontFamily: 'FontAwesome',
            content: theContent[ofFive],
            color: '#ffd800',
            float: 'right',
          },
        };
      },
    };

    setCustomStyles(customStyles);
    setNetworkOptions(integrationGroupData);
  }
};

const NetworkSelectBox = ({
  integrationData = [],
  isDisabled = false,
  filteredRelevant = [],
  selection = null,
  tissue = null,
  onSelect = null,
  onSubmit = null,
}) => {
  const [updatedSelection, setUpdatedSelection] = useState(selection);
  const [customStyles, setCustomStyles] = useState({});
  const [networkOptions, setNetworkOptions] = useState([]);

  useEffect(() => {
    getIntegrationGroupData(
      { filteredRelevant, integrationData },
      setCustomStyles,
      setNetworkOptions,
    );
  }, [filteredRelevant, integrationData]);

  useEffect(() => {
    setUpdatedSelection(selection);
  }, [selection]);

  const handleSelect = opt => {
    setUpdatedSelection(integrationData[opt.value]);
    if (onSelect) onSelect(integrationData[opt.value], tissue);
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit(updatedSelection, tissue);
  };

  return (
    <form
      style={{
        display: 'inline-flex',
        margin: 0,
        padding: 0,
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '10px',
          padding: 0,
          width: 'auto',
        }}
        id="integrationSelectLabel"
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label style={{ margin: 0 }} htmlFor="integrationSelect">
          Select network:{' '}
        </label>
      </div>
      <div
        style={{
          display: 'inline-block',
          padding: 0,
          marginRight: '5px',
          verticalAlign: 'middle',
        }}
      >
        <SearchableSelect
          isDisabled={isDisabled}
          id="integrationSelect"
          aria-labelledby="integrationSelectLabel"
          label={
            updatedSelection ? (
              updatedSelection?.context?.title
            ) : (
              <span>
                <i className="fa fa-refresh fa-spin" />
                <span> Fetching relevant datasets.</span>
              </span>
            )
          }
          onChange={handleSelect}
          options={networkOptions}
          styles={customStyles}
        />
      </div>
      <div style={{ display: 'inline-block', margin: 0, padding: 0 }}>
        {!onSubmit ? null : (
          <div style={{ display: 'inline-block', margin: 0, padding: 0 }}>
            <Button
              id="integrationSubmit"
              bsStyle="primary"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

NetworkSelectBox.propTypes = {
  integrationData: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
  filteredRelevant: PropTypes.arrayOf(PropTypes.object),
  selection: PropTypes.object, // eslint-disable-line
  tissue: PropTypes.shape({ group: PropTypes.string }),
  onSelect: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default NetworkSelectBox;
