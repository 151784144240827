import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

class ConfirmClear extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
  };

  render() {
    const { showModal, onCancel, onContinue } = this.props;
    return (
      <Modal show={showModal} onHide={onCancel}>
        <Modal.Body>
          <Alert bsStyle="warning">
            <i className="fa fa-exclamation-circle" /> If you proceed, the
            current search list will be lost. Do you wish to continue?
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCancel}>Cancel</Button>
          <Button bsStyle="primary" onClick={onContinue}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect()(ConfirmClear);
