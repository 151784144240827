import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import { getActivityMenuOptions } from '../../shared/activities';

const Option = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div>
        {data.label}
        {`${data.description}`}
      </div>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    label: PropTypes.object,
    description: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export const SearchDropdown = ({ onTargetSelect, target, options }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#66AFE9' : provided.borderColor,
      outline: 0,
      boxShadow: state.isFocused
        ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6)'
        : null,
    }),
    option: base => ({
      ...base,
      paddingTop: '5px',
      paddingBottom: '3px',
    }),
    menu: base => ({
      ...base,
      textAlign: 'left',
      width: 540,
    }),
  };

  const value = options.find(o => o.value === target);

  return (
    <Select
      instanceId="SearchDropdown"
      components={{ Option }}
      isSearchable={false}
      defaultValue={options[0]}
      value={target ? value : undefined} // if target is null, use default Select behaviour
      options={options}
      onChange={onTargetSelect}
      styles={customStyles}
    />
  );
};

SearchDropdown.propTypes = {
  onTargetSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  target: PropTypes.string,
};

SearchDropdown.defaultProps = {
  onTargetSelect: null,
  options: getActivityMenuOptions(),
  target: null,
};

export default SearchDropdown;
