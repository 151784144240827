import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Modal } from 'react-bootstrap';
import { Body, Header, Title } from 'react-bootstrap/lib/Modal';
import LordOfTheSearch from '../search/LordOfTheSearch';
import s from './GeneSelect.css';

const GeneSelect = ({ genes, show, onHide, handleSubmit }) => {
  const wrappedHandleSubmit = data => {
    if (handleSubmit) handleSubmit(data.genes);
    onHide();
  };

  const onSubmit = () => {
    onHide();
  };

  const mappedGenes = genes.map(e => ({
    query: e.standard_name,
    matches: [
      {
        entrez: e.entrez,
        standard_name: e.standard_name,
        description: e.description,
        aliases: e.aliases ? e.aliases.join('|') : '',
      },
    ],
  }));

  return (
    // See: https://github.com/react-bootstrap/react-bootstrap/issues/3105
    // eslint-disable-next-line  jsx-a11y/no-static-element-interactions
    <div onKeyDown={e => e.stopPropagation()}>
      <Modal
        dialogClassName={s.genesDialog}
        show={show}
        onHide={() => onHide()}
      >
        <Header closeButton>
          <Title>New Search</Title>
        </Header>
        <Body>
          <LordOfTheSearch
            initGenes={mappedGenes}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit ? wrappedHandleSubmit : null}
            singleSearchTarget
          />
        </Body>
      </Modal>
    </div>
  );
};

GeneSelect.propTypes = {
  genes: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

GeneSelect.defaultProps = {
  handleSubmit: null,
  show: false,
};

export default withStyles(s)(GeneSelect);
