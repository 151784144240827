// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bc8pg{margin-top:0;padding:0}._2IJc8{margin-top:10px;margin-bottom:10px;padding:0}._2zDjG{margin:auto}._18nVD{margin:50px auto 0;width:90%}._1JFxK{text-align:left}._3eAKG{text-align:center;margin-top:30px}._1zU3H{margin:5px auto 0;padding:0;width:80%}", ""]);
// Exports
exports.locals = {
	"searchboxtable": "Bc8pg",
	"buttonDiv": "_2IJc8",
	"submitButton": "_2zDjG",
	"query": "_18nVD",
	"goLeft": "_1JFxK",
	"exampleAlert": "_3eAKG",
	"progressBar": "_1zU3H"
};
module.exports = exports;
