import React, { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

// styled components
const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};

const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

// eslint-disable-next-line react/prop-types
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const SearchableSelect = ({
  customOption = null,
  isDisabled = false,
  label = '',
  options = [],
  onChange,
  styles = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(undefined);

  // Merge styles prop directly into default styles
  const customStyles = {
    groupHeading: base => ({
      ...base,
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontSize: '13px',
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    option: base => ({
      ...base,
      textAlign: 'left',
      whiteSpace: 'nowrap',
      marginRight: '70px',
    }),
    control: base => ({
      ...base,
      minWidth: 200,
      margin: 8,
      height: 34,
      minHeight: 0,
      boxShadow: 'none',
    }),
    ...styles,
  };

  const toggleOpen = useCallback(() => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  }, []);

  const onSelectChange = useCallback(
    opt => {
      toggleOpen();
      onChange(opt);
      setValue(opt.value);
    },
    [onChange, toggleOpen],
  );

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <Button onClick={toggleOpen} disabled={isDisabled}>
          <span style={{ marginRight: label?.length ? 10 : 0 }}>{label}</span>
          <i className="fa fa-chevron-down" />
        </Button>
      }
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator: null,
          IndicatorSeparator: null,
          Option: customOption || components.Option,
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectChange}
        options={options}
        placeholder="Search..."
        styles={customStyles}
        tabSelectsValue={false}
        value={value}
      />
    </Dropdown>
  );
};

SearchableSelect.propTypes = {
  customOption: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object,
};

export default SearchableSelect;
