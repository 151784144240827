import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { setCurrentSearchText } from '../../../actions/NetworkActions';
import s from './PasteForm.css';
import { setTextAreaRows } from '../../../core/doc_util';

function getPasteList(geneList) {
  return geneList.map(e => e.query).join(' ');
}

function PasteForm({ onChange, setIsPasteChanged }) {
  const dispatch = useDispatch();
  const text = useSelector(state => {
    const { currentSearchGenes, currentSearchText } = state.currentSearch;
    if (currentSearchGenes.length && currentSearchText === null) {
      return getPasteList(currentSearchGenes);
    }
    if (currentSearchText === null) {
      return '';
    }
    return currentSearchText;
  });

  const textAreaId = 'GenePasteBox';
  const timeoutRef = useRef(null);

  useEffect(() => {
    setTextAreaRows(textAreaId);
  }, []);

  useEffect(() => {
    setTextAreaRows(textAreaId);
  });

  const handleChange = useCallback(
    e => {
      const newText = e.target.value;
      dispatch(setCurrentSearchText(newText));
      setIsPasteChanged(true);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        const strippedText = newText.replace(/\s+/g, ' ');
        onChange(strippedText);
      }, 1000);
    },
    [dispatch, onChange, setIsPasteChanged],
  );

  return (
    <div>
      <textarea
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        className={`form-control ${s.textarea}`}
        id={textAreaId}
        onChange={handleChange}
        placeholder="Paste a list of genes here.."
        value={text}
      />
    </div>
  );
}

PasteForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  setIsPasteChanged: PropTypes.func.isRequired,
};

export default withStyles(s)(PasteForm);
