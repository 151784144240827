import { createMachine, MachineConfig, MachineOptions } from 'xstate';
import Cookies from 'universal-cookie';

import {
  HIDE_LATEST_BANNER_COOKIE,
  HIDE_LATEST_NOTIFICATION_COOKIE,
  MAX_COOKIE_AGE,
} from '../settings';
import history from '../history';
import { EmptyObject } from '../components/shared/sharedTypes';

type LoadCookiesEvent = {
  type: 'done.invoke.whatsNew.loadingCookies:invocation[0]';
  data: {
    isBannerHiddenCookie: boolean;
    isNotificationHiddenCookie: boolean;
  };
};

// The hierarchical (recursive) schema for the states
interface WhatsNewStateSchema {
  states: {
    loadingCookies: EmptyObject;
    unseen: EmptyObject;
    dismissed: EmptyObject;
    done: EmptyObject;
  };
}

// The events that the machine handles
type WhatsNewEvent = { type: 'VIEW' } | { type: 'DISMISS' } | LoadCookiesEvent;

const cookies = new Cookies();
const isLoadCookiesEvent = (
  event: WhatsNewEvent,
): event is LoadCookiesEvent => {
  return event.type === 'done.invoke.whatsNew.loadingCookies:invocation[0]';
};

export const whatsNewMachineConfig: MachineConfig<
  EmptyObject,
  WhatsNewStateSchema,
  WhatsNewEvent
> = {
  id: 'whatsNew',
  initial: 'loadingCookies',
  states: {
    loadingCookies: {
      invoke: {
        src: 'loadCookies',
        onDone: [
          {
            target: 'unseen',
            cond: 'isUnseen',
          },
          {
            target: 'done',
            cond: 'isDone',
          },
          {
            target: 'dismissed',
            cond: 'isDismissed',
          },
          {
            target: 'done',
            cond: 'isError',
            actions: ['throwStateError'],
          },
        ],
        onError: {
          target: 'done',
          actions: ['throwStateError'],
        },
      },
    },
    dismissed: {
      entry: ['doHideLatestBanner'],
      on: {
        VIEW: {
          target: 'done',
          actions: ['gotoWhatsNewPage'],
        },
      },
    },
    unseen: {
      on: {
        VIEW: {
          target: 'done',
          actions: ['gotoWhatsNewPage'],
        },
        DISMISS: { target: 'dismissed' },
      },
    },
    done: {
      entry: ['doHideLatestNotification', 'doHideLatestBanner'],
    },
  },
};

export const whatsNewMachineOptions: MachineOptions<
  EmptyObject,
  WhatsNewEvent
> = {
  activities: {},
  delays: {},
  services: {
    loadCookies: () => {
      const isBannerHiddenCookie = cookies.get(HIDE_LATEST_BANNER_COOKIE);
      const isNotificationHiddenCookie = cookies.get(
        HIDE_LATEST_NOTIFICATION_COOKIE,
      );
      return Promise.resolve({
        isBannerHiddenCookie,
        isNotificationHiddenCookie,
      });
    },
  },
  guards: {
    // Check guards are only run for `loadingCookies`
    isDismissed: (_, event) => {
      if (isLoadCookiesEvent(event)) {
        return (
          !event.data.isNotificationHiddenCookie &&
          event.data.isBannerHiddenCookie
        );
      }
      return false;
    },
    isDone: (_, event) => {
      if (isLoadCookiesEvent(event)) {
        return (
          event.data.isNotificationHiddenCookie &&
          event.data.isBannerHiddenCookie
        );
      }
      return false;
    },
    isUnseen: (_, event) => {
      if (isLoadCookiesEvent(event)) {
        return (
          !event.data.isNotificationHiddenCookie &&
          !event.data.isBannerHiddenCookie
        );
      }
      return false;
    },
    isError: (_, event) => {
      if (isLoadCookiesEvent(event)) {
        return (
          event.data.isNotificationHiddenCookie &&
          !event.data.isBannerHiddenCookie
        );
      }
      return true; // if we are not loading cookies, it is an error
    },
  },
  actions: {
    throwStateError: (context, event) => {
      throw new Error(JSON.stringify({ ...context, ...event }, null, 2));
    },
    doHideLatestNotification: () => {
      cookies.set(HIDE_LATEST_NOTIFICATION_COOKIE, true, {
        path: '/',
        maxAge: MAX_COOKIE_AGE,
      });
    },
    doHideLatestBanner: () => {
      cookies.set(HIDE_LATEST_BANNER_COOKIE, true, {
        path: '/',
        maxAge: MAX_COOKIE_AGE,
      });
    },
    gotoWhatsNewPage: () => {
      history.push('/whats-new');
    },
  },
};

export default createMachine(whatsNewMachineConfig, whatsNewMachineOptions);
