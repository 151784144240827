import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ReviewList.css';

function formatAliases(aliases) {
  return aliases.replace(/\|/g, ', ');
}

class ReviewList extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    selIdx: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    flexWidth: PropTypes.bool,
  };

  static defaultProps = {
    flexWidth: false,
    selIdx: [],
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // Radio buttons may also responde to click events. See
  // https://facebook.github.io/react/docs/forms.html and
  // https://stackoverflow.com/questions/27784212.
  handleChange(e) {
    const index = e.target.name.split('_')[1];
    const { value } = e.target;
    this.props.onChange(index, value);
  }

  render() {
    if (this.props.data.length === 0) {
      return null;
    }
    // Outer loop iterates over query terms and the first match for that
    // query term.
    const reviewRows = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0, trKey = 0; i < this.props.data.length; ++i) {
      const e = this.props.data[i];
      reviewRows.push(
        <tr key={(trKey += 1)}>
          <td rowSpan={e.matches.length} className={s.reviewcolor}>
            {i + 1}
          </td>
          <td className={s.searchTerm} rowSpan={e.matches.length}>
            {e.query}
          </td>
          <td className={s.narrow}>{e.matches[0].entrez}</td>
          <td className={s.stdName}>{e.matches[0].standard_name}</td>
          <td className={this.props.flexWidth ? s.leftFlex : s.left}>
            <div className={this.props.flexWidth ? s.maskFlex : ''}>
              {formatAliases(e.matches[0].aliases)}
            </div>
          </td>
          <td className={this.props.flexWidth ? s.leftFlex : s.left}>
            <div className={this.props.flexWidth ? s.maskFlex : ''}>
              {e.matches[0].description}
            </div>
          </td>
          {/* Don't use defaultChecked here, which makes the component
                uncontrolled, triggering React warnings. */}
          <td className={s.selectcol}>
            <input
              type="radio"
              name={'sel_'.concat(i)}
              value={0}
              checked={this.props.selIdx[i] === 0}
              onChange={this.handleChange}
            />
          </td>
        </tr>,
      );

      // Inner loop iterates over rest of the matches for this query term.
      // Counter starts at 1 since 0 was consumed previously.
      // eslint-disable-next-line no-plusplus
      for (let j = 1; j < e.num_matches; ++j) {
        reviewRows.push(
          <tr key={(trKey += 1)}>
            <td className={s.narrow}>{e.matches[j].entrez}</td>
            <td className={s.stdName}>{e.matches[j].standard_name}</td>
            <td className={this.props.flexWidth ? s.leftFlex : s.left}>
              <div className={this.props.flexWidth ? s.maskFlex : ''}>
                {formatAliases(e.matches[j].aliases)}
              </div>
            </td>
            <td className={this.props.flexWidth ? s.leftFlex : s.left}>
              <div className={this.props.flexWidth ? s.maskFlex : ''}>
                {e.matches[j].description}
              </div>
            </td>
            <td className={s.selectcol}>
              <input
                type="radio"
                name={'sel_'.concat(i)}
                value={j}
                checked={this.props.selIdx[i] === j}
                onChange={this.handleChange}
              />
            </td>
          </tr>,
        );
      }
    }

    return (
      <div>
        <table className={`${s.table} table table-striped`}>
          <thead>
            <tr>
              <th className={s.invisible} />
              <th>Search term</th>
              <th>Entrez id</th>
              <th>Standard name</th>
              <th>Aliases</th>
              <th>Description</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>{reviewRows}</tbody>
        </table>
      </div>
    );
  }
}

export default withStyles(s)(ReviewList);
