import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import NavbarHeader from 'react-bootstrap/lib/NavbarHeader';
import NavbarBrand from 'react-bootstrap/lib/NavbarBrand';
import NavbarCollapse from 'react-bootstrap/lib/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/lib/NavbarToggle';
import { useMachine } from '@xstate/react';

import { startTutorial } from '../../actions/TutorialActions';
import Link from '../Link';
import Tooltip from '../Tooltip';
import GeneSelectButton from '../GeneSelectButton';
import MailingList from '../MailingList';
import Debug from '../Debug';
import { DEBUG_XSTATE_WHATS_NEW, DOC_URL, TITLE } from '../../settings';
import s from './Header.css';
import { getActivityMenuOptions } from '../shared/activities';
import NotificationIcon from '../NotificationIcon';
import whatsNewMachine from '../../machines/whatsNewMachine';
import {
  getUrlFromActivity,
  isUsingDevToolsFromContext,
} from '../../core/util';
import Breadcrumb from '../Breadcrumb';
import ApplicationContext from '../ApplicationContext';

const createActivityMenuItems = () => {
  return getActivityMenuOptions().map((item, idx) => {
    const href = getUrlFromActivity(item);
    return (
      <MenuItem key={idx} eventKey={idx} href={href}>
        {item.label} {item.description}
      </MenuItem>
    );
  });
};

const Header = ({
  dispatch,
  whatsNewMachine,
  showTutorial,
  hideLogo,
  showSearch,
  showResources,
  showHelp,
  showMailingList,
  aboutUrl,
  whatsNewUrl,
  helpComponent,
}) => {
  const globalContext = useContext(ApplicationContext);
  const [stateWhatsNew, sendWhatsNew] = useMachine(whatsNewMachine, {
    devTools: isUsingDevToolsFromContext(globalContext, DEBUG_XSTATE_WHATS_NEW),
  });

  return (
    <div className={s.root}>
      <Navbar fixedTop>
        <NavbarHeader>
          <NavbarToggle />
        </NavbarHeader>

        {stateWhatsNew.matches('unseen') ? (
          <NavbarCollapse className={s.bannerNav}>
            <div className="container">
              <div className={s.banner}>
                <div className={s.bannerMain}>
                  We’ve made some changes and we’d like to show you around!
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => sendWhatsNew({ type: 'DISMISS' })}
                  >
                    Dismiss
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => sendWhatsNew({ type: 'VIEW' })}
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </NavbarCollapse>
        ) : (
          ''
        )}

        <div className="container">
          <NavbarCollapse>
            <NavbarBrand>
              {!hideLogo && (
                <div style={{ position: 'relative' }}>
                  <Link to="/">
                    <img
                      src="/hb_logo.svg"
                      alt="HumanBase logo"
                      height="30px"
                      id="svg_header"
                    />
                  </Link>
                  <Breadcrumb />
                </div>
              )}
            </NavbarBrand>
            <Nav pullRight>
              <NavDropdown
                id="analyses"
                title={
                  <Tooltip placement="bottom" text="Analyses">
                    <div style={{ display: 'inline' }}>
                      <i className="fa fa-th-large" />
                      &nbsp; Analyses
                    </div>
                  </Tooltip>
                }
              >
                {createActivityMenuItems()}
              </NavDropdown>
              {showResources && (
                <NavDropdown
                  id="resources"
                  title={
                    <Tooltip placement="bottom" text="Resources">
                      <div style={{ display: 'inline' }}>
                        <i className="fa fa-database" />
                        &nbsp; Resources
                      </div>
                    </Tooltip>
                  }
                >
                  <MenuItem eventKey={5} href="/data">
                    <i className="fa fa-database fa-fw" aria-hidden="true" />
                    &nbsp; Data sources
                  </MenuItem>
                  <MenuItem eventKey={6} href="/download">
                    <i className="fa fa-download fa-fw" aria-hidden="true" />
                    &nbsp; Network downloads
                  </MenuItem>
                  <MenuItem eventKey={7} href="/api/docs/">
                    <i className="fa fa-cogs fa-fw" aria-hidden="true" />
                    &nbsp; {`${TITLE} API`}
                  </MenuItem>
                </NavDropdown>
              )}
              {showHelp && !helpComponent ? (
                <NavDropdown
                  id="help"
                  title={
                    <Tooltip placement="bottom" text="Help">
                      <div style={{ display: 'inline' }}>
                        <i className="fa fa-question-circle" />
                        &nbsp; Help
                      </div>
                    </Tooltip>
                  }
                >
                  <MenuItem eventKey={8} href="/studies">
                    <i className="fa fa-clone fa-fw" aria-hidden="true" />
                    &nbsp; Case studies
                  </MenuItem>
                  <MenuItem eventKey={9} href={DOC_URL}>
                    <i className="fa fa-book fa-fw" aria-hidden="true" />
                    &nbsp; Documentation
                  </MenuItem>
                  {showTutorial && (
                    <MenuItem
                      eventKey={10}
                      onClick={() => dispatch(startTutorial())}
                    >
                      <i className="fa fa-question fa-fw" aria-hidden="true" />
                      &nbsp; Tutorial
                    </MenuItem>
                  )}
                </NavDropdown>
              ) : (
                helpComponent && <NavItem>{helpComponent}</NavItem>
              )}
              <NavDropdown
                id="about"
                title={
                  <Tooltip placement="bottom" text="About">
                    <div style={{ display: 'inline' }}>
                      About
                      <NotificationIcon
                        isHidden={
                          stateWhatsNew.matches('done') ||
                          stateWhatsNew.matches('loadingCookies')
                        }
                      />
                    </div>
                  </Tooltip>
                }
              >
                <MenuItem eventKey={11} href={aboutUrl}>
                  About
                </MenuItem>
                <MenuItem
                  eventKey={12}
                  href={whatsNewUrl}
                  onClick={() => sendWhatsNew('VIEW')}
                >
                  What&apos;s New
                  <NotificationIcon
                    isHidden={
                      stateWhatsNew.matches('done') ||
                      stateWhatsNew.matches('loadingCookies')
                    }
                  />
                </MenuItem>
              </NavDropdown>
              {showMailingList && (
                <NavItem eventKey={12}>
                  <MailingList />
                </NavItem>
              )}
              {process.env.NODE_ENV !== 'production' &&
                typeof window !== 'undefined' &&
                window.location.hash.includes('#debug') && <Debug />}
              {showSearch && (
                <NavItem eventKey={13}>
                  <GeneSelectButton
                    buttonText={
                      <div>
                        <i className="fa fa-search" aria-hidden="true" />
                        {' New query'}
                      </div>
                    }
                  />
                </NavItem>
              )}
            </Nav>
          </NavbarCollapse>
        </div>
      </Navbar>
    </div>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  whatsNewMachine: PropTypes.object,
  showTutorial: PropTypes.bool,
  showResources: PropTypes.bool,
  showHelp: PropTypes.bool,
  showSearch: PropTypes.bool,
  showMailingList: PropTypes.bool,
  hideLogo: PropTypes.bool,
  aboutUrl: PropTypes.string,
  whatsNewUrl: PropTypes.string,
  helpComponent: PropTypes.element,
};

Header.defaultProps = {
  dispatch: null,
  whatsNewMachine,
  showTutorial: false,
  hideLogo: false,
  showSearch: false,
  showHelp: true,
  showResources: true,
  aboutUrl: '/about',
  whatsNewUrl: '/whats-new',
  helpComponent: null,
  showMailingList: true,
};

export default withStyles(s)(Header);
