// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1U0ik{margin:0;padding:0}._3SbFy{width:100%}._32SDl{width:80%;margin:5px auto 0}._1llvg{padding-left:20px;font-size:90%;color:#666}", ""]);
// Exports
exports.locals = {
	"column": "_1U0ik",
	"grid": "_3SbFy",
	"search": "_32SDl",
	"resultSubtitle": "_1llvg"
};
module.exports = exports;
