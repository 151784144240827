// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2rEvL{width:1100px}._2rEvL * ul{float:none}", ""]);
// Exports
exports.locals = {
	"genesDialog": "_2rEvL"
};
module.exports = exports;
