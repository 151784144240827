// Utility functions that require window as a dependency
import canvg from 'canvg-browser';

import * as d3 from 'd3';

function convertSVGImageToPNG(img) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL('image/png');
}

function addLogoToCommunitySVG(svg) {
  const svgFooterObject = document
    .getElementById('svg_footer_object')
    .getSVGDocument()
    .children[0].cloneNode(true);
  const svgFooterContainer = document.getElementById('svg_footer');
  const containerWidth = svgFooterContainer.width.baseVal.value;
  const containerHeight = svgFooterContainer.height.baseVal.value;
  svgFooterObject.setAttribute('width', containerWidth);
  svgFooterObject.setAttribute('height', containerHeight);

  // Add a clickable link to the SVG
  const svgLink = document
    .getElementById('svg_footer_link')
    .appendChild(svgFooterObject);

  // Moves the node to be most recent, so it is visible
  svg.appendChild(svgFooterContainer);

  const svgData = new XMLSerializer().serializeToString(svg);

  // Return DOM to original state
  while (svgLink.hasChildNodes()) {
    svgLink.removeChild(svgLink.lastChild);
  }
  document.getElementById('svg_master').insertBefore(svgFooterContainer, svg);

  return svgData;
}

function convertSVGToPNG(svg, width) {
  const _devicePixelRatio = window.devicePixelRatio || 1;
  const scaleRatio = _devicePixelRatio + 4;
  const svgVB = svg.viewBox.baseVal;
  const svgWidth = svg.width.baseVal.value;
  const svgHeight = svg.height.baseVal.value;
  const scale = width / svgWidth;
  const canvas = document.createElement('canvas');
  const scaledW = Math.floor(svgWidth * scale);
  const scaledH = Math.floor(svgHeight * scale);
  canvas.width = scaledW;
  canvas.height = scaledH;
  svg.setAttribute('viewBox', `0 0 ${svgWidth} ${svgHeight}`);
  svg.setAttribute('width', svgWidth * scaleRatio);
  svg.setAttribute('height', svgHeight * scaleRatio);
  const svgData = addLogoToCommunitySVG(svg);
  svg.setAttribute(
    'viewBox',
    `${svgVB.x} ${svgVB.y} ${svgVB.width} ${svgVB.height}`,
  );
  svg.setAttribute('width', svgWidth);
  svg.setAttribute('height', svgHeight);
  canvg(canvas, svgData);
  return canvas.toDataURL('image/png');
}

function getCommunitySVG() {
  const svg = document.getElementById('svg_view');
  return addLogoToCommunitySVG(svg);
}

function communitySvgToImage() {
  const scale = 4;
  const svg = document.getElementById('svg_view');
  const width = svg.width.baseVal.value;
  const height = svg.height.baseVal.value;
  svg.setAttribute('width', width * scale);
  svg.setAttribute('height', height * scale);
  const svgData = addLogoToCommunitySVG(svg);
  const image = new Image();
  image.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  svg.setAttribute('width', width);
  svg.setAttribute('height', height);
  return image;
}

function svgToImage(elementId) {
  const svg = document.getElementById(elementId);
  if (!svg) return '';

  const svgData = new XMLSerializer().serializeToString(svg);
  const image = `data:image/svg+xml;base64,${btoa(svgData)}`;
  return image;
}

function getColorScale(
  // The default colors / scale distribution
  domain = [0, 0.15, 1],
  colorRange = ['#2ca02c', '#e7ba52', '#ca0020'],
) {
  return d3
    .scaleLinear()
    .domain(domain)
    .range(colorRange);
}

function getTextWidth(text, style = {}) {
  let lDiv = document.createElement('div');
  document.body.appendChild(lDiv);

  lDiv.style.fontFamily = 'Nunito,sans-serif';
  lDiv.style.fontSize = '13px';
  lDiv.style.position = 'absolute';
  lDiv.style.left = -1000;
  lDiv.style.top = -1000;

  Object.keys(style).forEach(key => {
    lDiv.style[key] = style[key];
  });

  lDiv.innerHTML = text;
  const { clientWidth } = lDiv;
  document.body.removeChild(lDiv);
  lDiv = null;
  return clientWidth;
}

function getTextHeight(text, divWidth, initialMult) {
  // This is a hack to predict the height of some text
  const labelMargin = 120;
  let summaryMultiplier = initialMult;

  summaryMultiplier +=
    Math.ceil(getTextWidth(text) / (divWidth - labelMargin)) - 1;

  return summaryMultiplier;
}

function setTextAreaRows(id, maxRows = 15) {
  const minRows = 6;
  const el = document.getElementById(id);
  const style = window.getComputedStyle
    ? window.getComputedStyle(el)
    : el.currentStyle;

  // This will get the line-height only if it is set in the css,
  // otherwise it's "normal"
  const lineHeight = parseInt(style.lineHeight, 10);
  // Get the scroll height of the textarea

  // const taHeight = _calculateTextAreaHeight(el, taLineHeight);
  let height = el.offsetHeight;
  const origHeight = el.style.height;
  const { scrollHeight } = el;
  const { overflow } = el.style;

  // only bother if the ta is bigger than content
  if (height >= scrollHeight) {
    // check that our browser supports changing dimension
    // calculations mid-way through a function call...
    el.style.height = `${height + lineHeight}px`;
    // because the scrollbar can cause calculation problems
    el.style.overflow = 'hidden';
    // by checking that scrollHeight has updated
    if (scrollHeight < el.scrollHeight) {
      // now try and scan the ta's height downwards
      // until scrollHeight becomes larger than height
      while (el.offsetHeight >= el.scrollHeight) {
        height -= lineHeight;
        el.style.height = `${height}px`;
      }
      // be more specific to get the exact height
      while (el.offsetHeight < el.scrollHeight) {
        height += 1;
        el.style.height = `${height}px`;
      }
      // reset the ta back to it's original height
      el.style.height = origHeight;
      // put the overflow back
      el.style.overflow = overflow;
    }
  } else {
    height = scrollHeight;
  }

  el.rows = Math.min(
    Math.max(Math.ceil(height / lineHeight), minRows),
    maxRows,
  );
}

export {
  communitySvgToImage,
  convertSVGImageToPNG,
  convertSVGToPNG,
  getColorScale,
  getCommunitySVG,
  getTextHeight,
  getTextWidth,
  setTextAreaRows,
  svgToImage,
};
