// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ltQE{width:100%;border:1px solid #d3d3d3}._3ltQE th{text-align:center}._380qL{min-width:30px;padding:0 10px;background-color:#fcf8e3}._3LtGf{background-color:#fff;border:0 solid grey;width:40px}._2eE6P{min-width:70px}._2eE6P,._1cMAm{text-align:center}._1cMAm{min-width:108px}.SR6UV{min-width:89px}.SR6UV,._1590T{text-align:center}._1Prns{width:50%;text-align:left}._2NnSR,._3Nmsa{text-align:left;overflow:hidden;text-overflow:ellipsis}", ""]);
// Exports
exports.locals = {
	"table": "_3ltQE",
	"reviewcolor": "_380qL",
	"invisible": "_3LtGf",
	"narrow": "_2eE6P",
	"stdName": "_1cMAm",
	"searchTerm": "SR6UV",
	"selectcol": "_1590T",
	"left": "_1Prns",
	"leftFlex": "_2NnSR",
	"maskFlex": "_3Nmsa"
};
module.exports = exports;
