// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2WzV4{text-align:left}", ""]);
// Exports
exports.locals = {
	"alert": "_2WzV4"
};
module.exports = exports;
