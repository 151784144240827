// eslint-disable-next-line import/prefer-default-export
export function looksLikeList(query) {
  // First replace all separators with the same separator character otherwise
  // a pattern like ", , , " will count as multiple separators. Also these are
  // internal spaces since leading/trailing spaces were previously trimmed.
  let queryRepl = query.replace(/ +/g, ',');
  queryRepl = queryRepl.replace(/,+/g, ',');

  // https://stackoverflow.com/questions/881085/count-the-number-of-occurences-of-a-character-in-a-string-in-javascript
  const numSeparators = (queryRepl.match(/,/g) || []).length;

  // When checking query length, exclude separators.
  queryRepl = queryRepl.replace(/,/g, '');
  const queryLength = queryRepl.length;

  // 10 is the greater of the max n-gram length (which is 10 for all
  // autocompleting fields) and max possible data length for other fields
  // (which is 9 for Entrez id).
  if (numSeparators > 0 && queryLength > 10) {
    return true;
  }
  return false;
}
