import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ValidList.css';

function formatAliases(aliases) {
  if (!aliases) return ''; // check if aliases is undefined or null
  return aliases.replace(/\|/g, ', ');
}

class ValidList extends Component {
  static propTypes = {
    allowDelete: PropTypes.bool,
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    allowDelete: false,
    onDelete: null,
  };

  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete(i) {
    if (this.props.allowDelete && this.props.onDelete) {
      this.props.onDelete(i);
    }
  }

  render() {
    if (this.props.data.length === 0) {
      return null;
    }
    let i = -1;
    const that = this;
    const validRows = this.props.data.map(e => {
      i += 1;
      let deleteTD = null;
      if (that.props.allowDelete) {
        deleteTD = (
          <td className={s.closebutton} style={{ verticalAlign: 'middle' }}>
            <Button
              type="button"
              bsSize="xsmall"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={that.handleDelete.bind(this, i)}
            >
              <i className="fa fa-times" />
            </Button>
          </td>
        );
      }
      const row = (
        <tr key={i}>
          <td className={s.validcolor}>{i + 1}</td>
          <td className={s.searchTerm}>{e.query}</td>
          <td className={s.narrow}>{e.matches[0].entrez}</td>
          <td className={s.stdName}>{e.matches[0].standard_name}</td>
          <td className={s.left}>{formatAliases(e.matches[0].aliases)}</td>
          <td className={s.left}>{e.matches[0].description}</td>
          {deleteTD}
        </tr>
      );
      return row;
    });

    let deleteTH = null;
    if (this.props.allowDelete) {
      deleteTH = <th className={s.invisible} />;
    }

    let alert = null;
    if (this.props.data.length >= 3) {
      alert = (
        <Alert className={s.alert} bsStyle="info">
          <i className="fa fa-lightbulb-o" /> Valid search terms:
        </Alert>
      );
    }

    return (
      <div className={s.validlist}>
        {alert}
        <table className={`table table-striped ${s.table}`}>
          <thead>
            <tr>
              <th className={s.invisible} />
              <th>Search term</th>
              <th>Entrez id</th>
              <th>Standard name</th>
              <th>Aliases</th>
              <th>Description</th>
              {deleteTH}
            </tr>
          </thead>
          <tbody>{validRows}</tbody>
        </table>
      </div>
    );
  }
}

export default withStyles(s)(ValidList);
