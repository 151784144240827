// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Kr6w{margin-top:5px}.HKz1Z{text-align:left}", ""]);
// Exports
exports.locals = {
	"reviewmsg": "_3Kr6w",
	"alert": "HKz1Z"
};
module.exports = exports;
