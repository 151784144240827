import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import { useMeasure } from 'react-use';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import s from './Tooltip.css';

type NewToolTipProps = {
  children: React.ReactElement | null;
  content: React.ReactElement | null;
  direction?: 'top' | 'bottom' | 'left' | 'right'; // 'right' is default;
  offsetDistance?: number;
  useStyle?: boolean; // for when the tooltip content is dynamic, see <EnrichmentResult />
};

const NewTooltip: React.FC<NewToolTipProps> = React.memo(
  ({ children, content, direction, useStyle, offsetDistance = 5 }) => {
    const [
      childRef,
      {
        width: childWidth,
        height: childHeight,
        left: childLeft,
        top: childTop,
      },
    ] = useMeasure<HTMLDivElement>();
    const [
      tooltipRef,
      { width: tooltipWidth, height: tooltipHeight },
    ] = useMeasure<HTMLDivElement>();

    const props = children?.props || {};
    const { style = {} } = props;
    const styleWidth = style?.width;
    const styleHeight = style?.height;
    const styleLeft = style?.left;
    const styleTop = style?.top;

    const height = parseInt(
      (useStyle ? styleHeight : childHeight).toString(),
      10,
    );
    const width = parseInt((useStyle ? styleWidth : childWidth).toString(), 10);
    const top = parseInt((useStyle ? styleTop : childTop).toString(), 10);
    const left = parseInt((useStyle ? styleLeft : childLeft).toString(), 10);

    // eslint-disable-next-line no-restricted-globals
    const leftOffset = left - tooltipWidth - width - offsetDistance;
    const rightOffset = left + width + offsetDistance; // default
    const offsetWidth =
      direction === 'left'
        ? leftOffset
        : direction === 'bottom' || direction === 'top'
        ? 0
        : rightOffset; // default, right
    const offsetHeight =
      direction === 'bottom'
        ? top + height + offsetDistance
        : direction === 'top'
        ? top - height - tooltipHeight - offsetDistance
        : top; // default, parallel with element

    return (
      <div className={s.container}>
        <div ref={childRef}>{children}</div>
        <div
          ref={tooltipRef}
          className={s.showHide}
          style={{
            top: offsetHeight,
            left: offsetWidth,
            whiteSpace: 'normal',
            borderRadius: '10px',
            border: '1px solid black',
            padding: '10px',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            fontSize: '12px',
          }}
        >
          {content}
        </div>
      </div>
    );
  },
);

// LEGACY

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore -- this is an old JSX component, legacy, please use NewTooltip
const HelpTooltip = ({ text, id, placement, show, url, children }) => {
  const tooltip = <Tooltip id={id || text}>{text}</Tooltip>;
  return (
    <OverlayTrigger
      defaultOverlayShown={show || false}
      placement={placement || 'top'}
      overlay={tooltip}
      delayShow={300}
      delayHide={150}
      rootClose
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {children ? (
        <span>{children}</span>
      ) : url ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={url} target="_blank">
          <i className={`${s.helpIcon} fa fa-book`} />
        </a>
      ) : (
        <i className={`${s.helpIcon} fa fa-question-circle`} />
      )}
    </OverlayTrigger>
  );
};

NewTooltip.displayName = 'NewTooltip';

export { NewTooltip };
export default withStyles(s)(HelpTooltip);
