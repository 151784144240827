import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './NotificationIcon.css';

type Props = {
  isHidden: boolean;
};

const NotificationIcon = ({ isHidden }: Props): JSX.Element | null => {
  return isHidden ? null : (
    <span className={s.notification}>
      <i className="fa fa-circle" />
    </span>
  );
};

export default withStyles(s)(NotificationIcon);
