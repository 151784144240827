import React, { useState, useCallback } from 'react';
import { Formik } from 'formik';
import jsonp from 'jsonp'; // eslint-disable-line import/no-unresolved, import/extensions
import {
  Alert,
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';
import debounce from 'lodash/debounce';

import { MAILCHIMP_SUBSCRIBE_URL } from '../../settings';

const ERROR = 'error';
const SUCCESS = 'success';
const DUPLICATE = 'duplicate';

const statusMessage = {
  [DUPLICATE]: 'You are already subscribed to the newsletter',
  [ERROR]: 'Please enter a valid email address.',
  [SUCCESS]: 'Welcome to the Humanbase Newsletter!',
};

const MailingList = () => {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState('');
  const [duplicateMessage, setDuplicateMessage] = useState('');

  const handleClose = () => {
    setShow(false);
    setStatus('');
  };
  const delayedClosing = useCallback(
    debounce(() => handleClose(), 3000),
    [],
  );
  const handleShow = () => setShow(true);

  const getAjaxUrl = url => url.replace('/post?', '/post-json?');

  const sendData = email => {
    jsonp(
      `${getAjaxUrl(MAILCHIMP_SUBSCRIBE_URL)}&EMAIL=${email}`,
      { param: 'c' },
      (err, data) => {
        if (data.msg.includes('already subscribed')) {
          const urlReg = /\b(https?:\/\/.*?\.[a-z]{2,4}\/[^"]*\b)/g;
          setDuplicateMessage(
            <Alert bsStyle="danger">
              {email} is already subscribed to list HumanBase. <br />
              <a
                href="#"
                onClick={() => window.open(data.msg.match(urlReg)[0])}
              >
                Click here to update your profile
              </a>
            </Alert>,
          );
          setStatus(DUPLICATE);
        } else if (err || !data || data.result !== 'success') {
          setStatus(ERROR);
        } else {
          setStatus(SUCCESS);
          delayedClosing();
        }
      },
    );
  };

  return (
    <div>
      <div tabIndex="0" role="button" onClick={handleShow}>
        <i className="fa fa-envelope fa-fw" aria-hidden="true" />
        &nbsp; Mailing list
      </div>
      <Modal style={{ fontSize: '150%' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        {status === SUCCESS ? (
          <Modal.Body>{statusMessage[status]}</Modal.Body>
        ) : (
          <Modal.Body>
            {status === DUPLICATE ? duplicateMessage : ''}
            <p>
              Sign up for updates to the HumanBase platform.
              <br />
              <br />
              We&apos;ll inform you periodically when HumanBase data and
              predictions are updated, or when exciting new features are added.
              Your privacy is important to us and your email will never be given
              to a 3rd party.
            </p>
            <br />
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={values => sendData(values.email)}
            >
              {({ handleSubmit, handleChange, values }) => (
                <form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <ControlLabel>Email Address:</ControlLabel>
                  <FormGroup
                    controlId="formControlsEmail"
                    validationState={status === ERROR ? 'error' : 'success'}
                  >
                    <FormControl
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                      type="email"
                      placeholder="Enter email"
                    />
                    {status === ERROR ? (
                      <HelpBlock>{statusMessage[status]}</HelpBlock>
                    ) : (
                      ''
                    )}
                  </FormGroup>

                  <Button
                    bsStyle="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Subscribe
                  </Button>
                  <br />
                </form>
              )}
            </Formik>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default MailingList;
