import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SearchErrorMsg.css';

class SearchErrorMsg extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    let genes = this.props.data.map(e => e.query);
    genes = genes.join(', ');
    if (genes) {
      return (
        <Alert className={s.alert} bsStyle="danger">
          <p>
            <i className="fa fa-exclamation-triangle" /> The following search
            term(s) did not match any genes:
          </p>
          <p>
            <strong>{genes}</strong>
          </p>
        </Alert>
      );
    }
    return null;
  }
}

export default withStyles(s)(SearchErrorMsg);
