import React, { ReactElement } from 'react';

// Define the structure of a single activity option
interface ActivityOption {
  customUrl?: string; // If this is defined, it will override the default url
  description?: string;
  label?: ReactElement; // If this is NOT defined, **it will NOT appear as a menu option**
  text: string;
  value?: string; // Same as key string & route segment, only needed if a URL is generated from the activity
  // If subPaths or subSearch are defined they will be checked for Breadcumb & Menu options
  subPaths?: { [key: string]: ActivityOption };
  subSearch?: { [key: string]: ActivityOption };
}

// Define the structure of activityOptions object
export interface ActivityOptions {
  [key: string]: ActivityOption;
}

const networkLabel = (
  <span>
    <i className="fa fa-connectdevelop fa-fw" aria-hidden="true" />
    &nbsp; <strong>Networks</strong>
  </span>
);

const moduleLabel = (
  <span>
    <i className="fa fa-certificate fa-fw" aria-hidden="true" />
    &nbsp; <strong>Modules</strong>
  </span>
);

const variantChromatinLabel = (
  <span>
    <img src="/deepsea/deepsea_logo.svg" alt="DeepSEA" width={20} />
    &nbsp; <strong>Sei</strong>
  </span>
);

const sequenceProfilerLabel = (
  <span>
    <i className="fa fa-th fa-fw" aria-hidden="true" />
    &nbsp; <strong>In silico mutagenesis</strong>
  </span>
);

const expectoscLabel = (
  <span>
    <img
      src="/expecto/expectosc_dendritic_cell.svg"
      alt="ExPectoSC"
      width={20}
    />
    &nbsp; <strong>ExPecto SC</strong>
  </span>
);

const expectoLabel = (
  <span>
    <i className="fa fa-magic fa-fw" aria-hidden="true" />
    &nbsp; <strong>ExPecto</strong>
  </span>
);

const gwasLabel = (
  <span>
    <i className="fa fa-list-ol fa-fw" aria-hidden="true" />
    &nbsp; <strong>GWAS</strong>
  </span>
);

const asdBrowserLabel = (
  <span>
    <strong>ASD Browser</strong>
  </span>
);

const seekLabel = (
  <span>
    <strong>Seek</strong>
  </span>
);

const activityOptions: ActivityOptions = {
  gene: {
    value: 'gene',
    text: 'Networks',
    label: networkLabel,
    description: ': tissue-specific gene networks (GIANT)',
  },
  module: {
    value: 'module',
    text: 'Modules',
    label: moduleLabel,
    description: ': functional module detection (large gene lists)',
    subPaths: {
      overview: { text: 'Overview' },
      detail: { text: 'Detail' },
      term: { text: 'Term' },
    },
  },
  sei: {
    value: 'sei',
    text: 'Sei',
    label: variantChromatinLabel,
    description: ': chromatin effects of variants',
  },
  deepsea: {
    value: 'deepsea',
    text: '',
    subPaths: {
      jobs: { text: 'Result' },
    },
    subSearch: {
      insilico: {
        customUrl: 'deepsea/?analysis=insilico',
        value: 'deepsea-insilico',
        text: 'In silico mutagenesis',
        label: sequenceProfilerLabel,
        description: ': discover regulatory features of sequences',
      },
    },
  },
  expectosc: {
    value: 'expectosc',
    text: 'ExPectoSC',
    label: expectoscLabel,
    description: ': cell-type-specific gene expression effects for mutations',
    subPaths: {
      gene: { text: 'Gene' },
    },
  },
  expecto: {
    value: 'expecto',
    text: 'ExPecto',
    label: expectoLabel,
    description: ': tissue-specific gene expression effects of variants',
    subPaths: {
      gene: { text: 'Gene' },
    },
  },
  netwas: {
    value: 'netwas',
    text: 'GWAS',
    label: gwasLabel,
    description: ': GWAS reprioritization (NetWAS)',
  },
  asdbrowser: {
    value: 'asdbrowser',
    text: 'ASD Browser',
    label: asdBrowserLabel,
    description: ': predicted effects of ASD proband mutations',
  },
  dis_asd: {
    text: 'ASD Browser',
  },
  seek: {
    value: 'seek',
    text: 'Seek',
    label: seekLabel,
    description: ': computational gene coexpression search engine',
    subPaths: {
      result: { text: 'Result' },
    },
  },
};

export default activityOptions;

export const getActivityMenuOptions = (): ActivityOption[] => {
  const menuOptions: ActivityOption[] = [];

  // Recursively check for menu options defined in `activityOptions`
  const addOptionsWithLabel = (activity: ActivityOption) => {
    if (activity.label !== undefined) {
      menuOptions.push(activity);
    }
    // If this activity has subPaths, check them as well
    if (activity.subPaths) {
      Object.values(activity.subPaths).forEach(subPath =>
        addOptionsWithLabel(subPath),
      );
    }
    // If this activity has subSearch, check them as well
    if (activity.subSearch) {
      Object.values(activity.subSearch).forEach(subSearch =>
        addOptionsWithLabel(subSearch),
      );
    }
  };

  Object.values(activityOptions).forEach(activity => {
    // TODO: When SEEK is ready, remove the conditional hiding the menu item
    // addOptionsWithLabel(activity);
    if (activity.value !== 'seek') addOptionsWithLabel(activity);
  });

  return menuOptions;
};
