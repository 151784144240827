import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './TermCountWarning.css';
import { MAX_RECOMMENDED_GENE_QUERY_SIZE } from '../../../settings';

class TermCountWarning extends Component {
  static propTypes = {
    numValidSearchGenes: PropTypes.number.isRequired,
    maxValidSearchGenes: PropTypes.number.isRequired,
  };

  render() {
    if (this.props.numValidSearchGenes > this.props.maxValidSearchGenes) {
      return (
        <Alert className={s.alert} bsStyle="danger">
          <i className="fa fa-exclamation-triangle" /> This list contains{' '}
          <strong>{this.props.numValidSearchGenes}</strong> genes, which exceeds
          the maximum number allowed. Please edit your input so that it contains
          no more than <strong>{this.props.maxValidSearchGenes}</strong> genes.
        </Alert>
      );
    }
    if (this.props.numValidSearchGenes > MAX_RECOMMENDED_GENE_QUERY_SIZE) {
      return (
        <Alert className={s.alert} bsStyle="warning">
          <i className="fa fa-exclamation-triangle" /> This list contains{' '}
          <strong>{this.props.numValidSearchGenes}</strong> genes, which exceeds
          the recommended number for proper visualization. For best performance,
          please specify at most{' '}
          <strong>{MAX_RECOMMENDED_GENE_QUERY_SIZE}</strong> genes.
        </Alert>
      );
    }
    return null;
  }
}

export default withStyles(s)(TermCountWarning);
