import { saveAs } from 'file-saver'; // eslint-disable-line

/**
 * Helper functions to save file content client-side
 *
 */
function saveImageAs(canvas, fileName) {
  saveAs(canvas.toDataURL('image/png'), fileName, { type: 'image/png' });
}

function saveImage(image, fileName, saveFn = saveImageAs) {
  // eslint-disable-next-line no-param-reassign
  image.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    const a = document.createElement('a');
    a.download = fileName;
    saveFn(canvas, fileName);
  };
  image.onload = image.onload.bind(this); // eslint-disable-line no-param-reassign
}

function saveText(content, fileName) {
  const uriContent = `data:application/octet-stream,${encodeURIComponent(
    content,
  )}`;
  saveAs(uriContent, fileName, { type: 'text/plain' });
}

export { saveText, saveImage };
