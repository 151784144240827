// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2hl-9{display:inline-block;margin:5px}", ""]);
// Exports
exports.locals = {
	"buttonDiv": "_2hl-9"
};
module.exports = exports;
